$(document).ready(function() {
  let timerInterval;

  function updateTimerDisplay(minutes, seconds) {
    $("#timer").text(`Resend in: ${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
  }

  function handleTimerExpiration() {
    clearInterval(timerInterval);
    $("#timer").addClass('hidden');
    $(".receive_pin").removeClass('hidden');
  }

  function startTimer(timestamp) {
  	$("#timer").removeClass('hidden');
  	$(".receive_pin").addClass('hidden');
    const twoMinutesAgo = new Date();
    twoMinutesAgo.setMinutes(twoMinutesAgo.getMinutes() - 2);

    timerInterval = setInterval(function() {
      const remainingSeconds = Math.floor((timestamp - twoMinutesAgo) / 1000);
      updateTimerDisplay(Math.floor(remainingSeconds / 60), remainingSeconds % 60);
      if (remainingSeconds <= 0) {
        handleTimerExpiration();
      }

      twoMinutesAgo.setSeconds(twoMinutesAgo.getSeconds() + 1);
    }, 1000);
  }

  $('body').on('click', '.resend-pin-verification', function() {
    $.ajax({
      url: '/resend_verification_email',
      method: 'GET',
      success: function(data) {
        const timestamp = parseInt($('#resendButton').data('time')) * 1000;
        startTimer(timestamp);
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.error('There was a problem with the AJAX request:', errorThrown);
      }
    });
  });

  const timestamp = parseInt($('#resendButton').data('time')) * 1000;
  startTimer(timestamp);
});



