import consumer from "./consumer"

consumer.subscriptions.create({
  channel: 'AssistantChannel'
}, {
  connected() {
    // Called when the subscription is established
    console.log('Connected to AssistantChannel');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log('Disconnected from AssistantChannel');
  },

  received(data) {
    // Called when data is broadcasted to the channel
    console.log('Received data:', data);
    // Handle the received data as needed
    if (data["status"] == "success"){
      if (data["assistant_status"] != "completed"){
        $("#send_assistant_message").removeClass("bg-gray-400");
        $("#send_assistant_message").addClass("bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400");
        $("#send_assistant_message").prop("disabled", false);
      }
      $("#message-loader").remove();
      message_body = data["message_body"];
      message_div = $("<div>");
      message_div.addClass("w-8/12 hidden");
      message_element = `
        <div class="text-base text-white bg-blue-500 assistant-message rounded-xl mb-5 max-w-max p-3">
          ${message_body}
        </div>
      `
      message_div.append(message_element);
      $(".thread").append(message_div);
      $("#thread-container").animate({ scrollTop: $('#thread-container').prop("scrollHeight")}, 1000);
      message_div.show(400);
      if (data["assistant_status"] == "completed"){
        var thread_closed_html = `<br>
                              <br>
                              <div class="flex flex-col items-center">
                                <div class="text-black bg-gray-200 rounded-2xl p-3 text-center w-96">Thread is closed, please load a new thread.</div>
                              </div>
                              <br>
                              <br>`
        $("#thread-container").append(thread_closed_html);
      }
      $("#thread-container").animate({ scrollTop: $('#thread-container').prop("scrollHeight")}, 1000);
    }
  }
});
